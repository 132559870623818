import React, { useEffect, useState } from "react";
import { Avatar } from "../../../../assets/DataIndex";
import AOS from "aos";
import "aos/dist/aos.css";
import { request } from "../../../utils/axios";
import { useQuery } from "react-query";
const UserComment = () => {
  const [comment, setComment] = useState([]);

  useEffect(() => {
    AOS.init();
  }, []);

  // fetch comments
  const fetchComments = () => {
    const url = "/Opinions";
    return request({ url });
  };

  const { isLoading, data, refetch } = useQuery("getComments", fetchComments, {
    onSuccess() {
      setComment(data?.data?.data);
      // console.log(comment);
    },
  });

  useEffect(() => {
    refetch();
  }, [comment]);


  return (
    <>
      {Array.isArray(comment) &&
        comment.map((user) => {
          return (
            <div
              key={user?.id}
              data-aos="flip-right"
              className="rounded-2xl bg-[#31225B] text-white p-6  mt-2 w-[310px] md:[w-350px] h-[170px]"
            >
              <div className=" flex items-center gap-x-2 ">
                {/* 
                <img src={Avatar} alt="avatar" className="rounded-full" /> 
                */}
                <span className="font-bold"> {user?.name}</span>
              </div>
              <p className="mt-3 text-[14px] text-center font-[400]">
                {user?.opinion}
              </p>
            </div>
          );
        })}
    </>
  );
};

export default UserComment;
