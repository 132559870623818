import React from "react";
import MainBtn from "../core/MainBtn";
import { useTranslation } from "react-i18next";
import { CertificateImage } from "../../../assets/DataIndex";
const Certificate = () => {
  const { t } = useTranslation();
  return (
    <div className="bg-[#31225B] rounded-xl text-white py-10 px-2 md:px-4   md:flex justify-around items-center mt-12">
      <div className=" flex flex-col items-center md:w-1/2">
        <span className="font-semibold text-lg md:text-xl ">
          {" "}
          {t("get certificate")}
        </span>
        <p className="pt-2 px-4 mt-6 mb-12 lg:w-[75%]  text-center md:text-right ">
          {t("desc")}
        </p>
        <div className="md:px-24">
          <MainBtn text={t("register with us now")} />
        </div>
      </div>
      <img
        src={CertificateImage}
        alt="Certificate_Image"
        className="w-[80%] md:w-[35%] mx-auto mt-4 md:mt-0"
      />
    </div>
  );
};

export default Certificate;
