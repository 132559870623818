import React, { useEffect } from "react";
import { Arrow } from "../../../assets/DataIndex";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { request } from "../../utils/axios";
import { useQuery } from "react-query";
import Spinner from "./Spinner";

const AboutUsSample = () => {
  const {  i18n } = useTranslation();
  const isEnglish = i18n.language === "en";
  const lang = localStorage.getItem("lang")
    ? JSON.parse(localStorage.getItem("lang"))
    : "ar";

  const fetchData = () => {
    const url = `/about_us?lang=${i18n.language || lang}`;
    return request({ url });
  };

  const { isLoading, data,  isError } = useQuery(
    "getAboutUs",
    fetchData,
    {
      onSuccess() {
        // setQuestions(data?.data?.data);
        // console.log(data?.data?.data);
      },
      onError(e) {
        console.log(e.message);
      },
    }
  );
  useEffect(() => {});
  if (isLoading) {
    return <Spinner />;
  }

  if (isError) {
    return <p>Error fetching data</p>;
  }

  // const content = data?.data?.data[0] || {};

  return (
    <motion.div initial={{ opacity: 0, x: 500 }} animate={{ opacity: 1, x: 0 }}>
      <div className="">
        {Array.isArray(data?.data?.data?.[0]) &&
          data?.data?.data?.[0]?.map((section, index) => (
            <div key={index}>
              <div className="flex items-center gap-x-1">
                <img
                  src={Arrow}
                  alt=""
                  className={`${isEnglish ? "rotate-180" : null}`}
                />
                <span className="text-[#9E72FB] font-bold text-lg py-2">
                  {section?.title}
                </span>
              </div>

              <ul className="pl-6 ">
                {section?.desc.split("\n").map(
                  (line, i) =>
                    line.trim() && (
                      <li className="list-disc py-1" key={i}>
                        {line}
                      </li>
                    )
                )}
              </ul>
            </div>
          ))}
      </div>
    </motion.div>
  );
};

export default AboutUsSample;
