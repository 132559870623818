import React, { useEffect } from "react";

import DataSummary from "../components/socialInterAction/DataSummary ";
import UserComment from "../components/socialInterAction/UserOpinions/UserComment";
import SendEvaluation from "../components/socialInterAction/UserOpinions/SendEvaluation";
import Hero from "../components/home/Hero";
import { Circle, ProgressImage } from "../../assets/DataIndex";
import Features from "../components/home/Features";
import AOS from "aos";
import "aos/dist/aos.css";
import Slider from "../components/core/trustedSlider/Slider";
import TeamSection from "../components/Team/Team";

const Home = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className="px-8 md:px-0">
      <Hero />
      <div className="flex justify-center">
        <img src={Circle} alt="circle" data-aos="zoom-in" />
      </div>
      <Features />
      <div className=" flex justify-center mt-24 px-8">
        <img data-aos="zoom-in" src={ProgressImage} alt="" className="" />
      </div>
      <Slider />
      <DataSummary />
      <TeamSection />
      <SendEvaluation />
      <div className="flex flex-wrap justify-center items-center gap-x-4  px-8 lg:px-16 mt-12">
        <UserComment />
      </div>
    </div>
  );
};

export default Home;
