import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FaChalkboardTeacher } from "react-icons/fa";
import { useQuery } from "react-query";
import { request } from "../../utils/axios";
import Spinner from "../core/Spinner";

const CourseType = ({ handleCourseTypeClick }) => {
  const { t, i18n } = useTranslation();

  const fetchData = () => {
    const url = `/category?lang=${i18n.language || "ar"}`;
    return request({ url });
  };

  const { isLoading, data, refetch } = useQuery(
    "getCourseCategory",
    fetchData,
    {
      onSuccess() {
        // console.log(data?.data?.data);
      },
    }
  );

  useEffect(() => {
    refetch();
  }, [i18n.language, refetch]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="flex justify-center items-center flex-wrap gap-2">
      {data?.data?.data?.map((card) => {
        return (
          <div
            key={card?.id}
            className="text-white bg-[#9E72FB] rounded-2xl p-4 min-w-44 max-w-44 h-24 text-center flex flex-col items-center justify-center cursor-pointer"
            onClick={() => handleCourseTypeClick(card?.id)}
          >
            <FaChalkboardTeacher className="w-8 h-8 mx-auto mb-2" />
            <p>{t(card?.name)}</p>
          </div>
        );
      })}
    </div>
  );
};

export default CourseType;
