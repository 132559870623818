import React from "react";
import NavLinks from "./NavLinks";

const Navbar = () => {
  return (
      <NavLinks />
  );
};

export default Navbar;
