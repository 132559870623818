// Slider.jsx
import React from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import Trusted1 from "../../../../assets/trusted/trusted1.svg";
import Trusted2 from "../../../../assets/trusted/trusted2.svg";
import Trusted3 from "../../../../assets/trusted/trusted3.svg";
import Trusted4 from "../../../../assets/trusted/trusted4.svg";
import Trusted5 from "../../../../assets/trusted/trusted5.svg";
import Trusted6 from "../../../../assets/trusted/trusted6.svg";
import { request } from "../../../utils/axios";
import TrustedSlider from "./TrustedSlider";

const imagesSet1 = [Trusted1, Trusted2, Trusted3, Trusted4, Trusted5, Trusted6];

const Slider = () => {
  const { i18n } = useTranslation();
  const lang = localStorage.getItem("lang")
    ? JSON.parse(localStorage.getItem("lang"))
    : "ar";

  const fetchData = () => {
    const url = `/partners`;
    return request({ url });
  };
  const { isLoading, data, refetch, isError } = useQuery(
    "getPartners",
    fetchData,
    {
      onSuccess() {
        // setQuestions(data?.data?.data);
        // console.log(data?.data?.data);
      },
      onError(e) {
        console.log(e.message);
      },
    }
  );
  return (
    <div className=" px-6 mt-6 flex justify-center items-center gap-x-12 gap-y-3 flex-wrap">
      {data?.data?.data.length > 0 && (
        <img
          key={0}
          src={data.data.data[0]?.logo}
          alt="image_"
          className="max-w-24 md:max-w-36 hover:scale-[1.15] transition-transform flex-auto object-cover"
        />
      )}
    </div>
  );
};

export default Slider;
