import React from "react";
import { Book, Clock, Level } from "../../../assets/DataIndex";

const info = [
  { image: Book, title: "course name", desc: "مقدمة عن الدورة التدريبية" },
  { image: Level, title: "course level", desc: "مبتدأ" },
  { image: Clock, title: "course time", desc: 5 },
];
const CourseInfo = ({ courseDetails }) => {
  return (
    <>
      <div className="w-[290px] p-4 bg-[#9E72FB] rounded-lg flex items-center gap-x-3 mt-3">
        <div className="w-14 h-14 rounded-full bg-white p-2  flex items-center justify-center">
          <img src={Book} alt="book" className="text-center" />
        </div>
        <div className="">
          <span className="font-bold text-lg">{courseDetails?.name}</span>
          {/* <p className="text-white">{desc}</p> */}
        </div>
      </div>

      <div className="w-[290px] p-4 bg-[#9E72FB] rounded-lg flex items-center gap-x-3 mt-3">
        <div className="w-14 h-14 rounded-full bg-white p-2  flex items-center justify-center">
          <img src={Level} alt="book" className="text-center" />
        </div>
        <div className="">
          <span className="font-bold text-lg">{courseDetails?.level}</span>
          {/* <p className="text-white">{desc}</p> */}
        </div>
      </div>

      <div className="w-[290px] p-4 bg-[#9E72FB] rounded-lg flex items-center gap-x-3 mt-3">
        <div className="w-14 h-14 rounded-full bg-white p-2  flex items-center justify-center">
          <img src={Clock} alt="book" className="text-center" />
        </div>
        <div className="">
          <span className="font-bold text-lg">{courseDetails?.duration}</span>
          {/* <p className="text-white">{desc}</p> */}
        </div>
      </div>
    </>
  );
};

export default CourseInfo;
