import React from "react";
import Title from "../core/Title";
import Rating from "react-rating";

const CourseDesc = ({ courseDetails }) => {
  return (
    <div className="my-6">
      {/* <div className="flex items-center gap-x-2 my-2 border p-2 rounded-lg w-fit shadow"> */}
        {/* <span className="font-bold text-lg">اضف تقيميك</span>
        <Rating
          initialRating={courseDetails?.rating}
          emptySymbol={<span className="text-gray-300 text-3xl">&#9734;</span>}
          fullSymbol={<span className="text-yellow-500 text-3xl">&#9733;</span>}
          onChange={(newRating) => console.log(`Rated: ${newRating}`)}
        /> */}
      {/* </div> */}
      <Title text={courseDetails?.name} items="items-start" />
      <p className="md:w-[80%] mt-4 mx-8">{courseDetails?.content}</p>
    </div>
  );
};

export default CourseDesc;
