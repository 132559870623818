import React from "react";
import { useTranslation } from "react-i18next";
import { FaLocationDot, FaPhone } from "react-icons/fa6";
import { IoMail } from "react-icons/io5";
import { Link } from "react-router-dom";

import SocialIcons from "./SocialIcons";

import { useQuery } from "react-query";
import { request } from "../../utils/axios";

const navLinks = [
  { name: "home", route: "/" },
  { name: "courses", route: "/courses" },
  { name: "experience", route: "/our-expert" },
  { name: "us", route: "/about-us" },
  { name: "contact", route: "/contact-us" },
];

const Footer = () => {
  //  =================== fetchData ===================
  const lang = localStorage.getItem("lang")
    ? JSON.parse(localStorage.getItem("lang"))
    : "ar";
  const fetchData = () => {
    return request({
      url: `/Contact/information?lang=${i18n.language || lang}`,
    });
  };
  const { data } = useQuery("getContactInfo", fetchData, {
    onSuccess: () => {
      // console.log(data?.data?.data[0]?.address);
    },
  });

  const addressData = [
    {
      title: "address",
      // disc: data?.data?.data[0]?.address,
      disc: "Mansoura, Egypt",
      icon: FaLocationDot,
    },
    {
      title: "phone",
      // disc: data?.data?.data[0]?.phone,
      disc: "+20 102 912 6073",
      icon: FaPhone,
    },
    {
      title: "email",
      // disc: data?.data?.data[0]?.Email,
      disc: "info.tsd@gmail.com",
      icon: IoMail,
    },
  ];

  // =================================
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar";
  return (
    <footer>
      <div className="bg-[#31225B] flex justify-start md:justify-start lg:justify-between flex-wrap gap-12 p-12 text-white">
        {/*=======================  col 1 ======================= */}
        <div className="flex flex-col gap-y-4 justify-center">
          <h2 className=" flex items-center gap-x-2 font-bold">
            <span className="block w-[3px] h-8 bg-white rounded"></span>
            {t("Contact us information")}
          </h2>
          {addressData.map((address, index) => {
            const IconComponent = address.icon;
            let linkHref = "#";

            // Set the correct href based on the type of data
            if (address.title === "address") {
              linkHref =
                "https://www.google.com/maps/place/31%C2%B003'02.6%22N+31%C2%B023'50.7%22E/@31.0507434,31.3972412,21z/data=!4m4!3m3!8m2!3d31.0507192!4d31.3974127?entry=ttu&g_ep=EgoyMDI0MTAwMi4xIKXMDSoASAFQAw%3D%3D";
            } else if (address.title === "phone") {
              linkHref = `tel:${address.disc}`;
            } else if (address.title === "email") {
              linkHref = `mailto:${address.disc}`;
            }

            // Return the JSX for each address
            return (
              <a
                key={index}
                href={linkHref}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-x-2 cursor-pointer"
              >
                <IconComponent
                  size={25}
                  className="w-8 h-8 p-1 rounded-md bg-white text-[#31225B]"
                />
                <div>
                  <p>{t(address.title)}</p>
                  <span className="text-sm">{address.disc}</span>
                </div>
              </a>
            );
          })}
        </div>

        {/*=======================  col 2 ======================= */}
        <div className=" flex flex-col gap-y-2">
          <h2 className="flex items-center gap-x-2 font-bold ">
            <span className="block w-[3px] h-8 bg-white rounded"></span>
            {t("our goal")}
          </h2>
          <p className="text-[12px] px-2 leading-6 ">{t("our main goal")}</p>
        </div>

        {/* ======================= col 3 =======================  */}
        <div className=" flex flex-col gap-y-2">
          <h2 className="  flex items-center gap-x-2 font-bold">
            <span className="block w-[3px] h-8 bg-white rounded"></span>
            {t("important services of the  development skills platform")}
          </h2>
          <ul className="flex flex-col justify-center">
            {navLinks.map((link, index) => (
              <li key={index} className="cursor-pointer my-1">
                <Link to={link.route} className={` font-medium text-[12px]`}>
                  {t(link.name)}
                </Link>
              </li>
            ))}
          </ul>
        </div>

        {/* ======================= social ===================== */}
        <div
          className="flex flex-col gap-y-4"
          style={{
            direction: isArabic ? "rtl" : "ltr",
          }}
        >
          <h2 className=" flex items-center gap-x-2 font-bold ">
            <span className="block w-[3px] h-8 bg-white rounded"></span>
            {t("Follow us on social media")}
          </h2>
          <SocialIcons />
        </div>
        <p className="text-center text-xs sm:text-base w-full">{t("rights")}</p>
      </div>
    </footer>
  );
};

export default Footer;
