import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import Studygroup from "../../../assets/studygroup.jpeg";
import { request } from "../../utils/axios";
import MainBtn from "../core/MainBtn";
import Title from "../core/Title";

const Hero = () => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar";
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init();
  }, []);

  const fetchData = () => {
    const url = "/themes/home";
    return request({ url });
  };
  const { data, isError: isErrorImage } = useQuery("getHeroImage", fetchData);

  const fetchText = () => {
    const url = "/ContentText/home/*";
    return request({ url });
  };

  const {
    data: dataText,
    isLoading: textLoading,
    isError: isErrorText,
  } = useQuery("getHomeText", fetchText);


  if (isErrorText) {
    return <p>Error: {isErrorText.message}</p>;
  }
  if (isErrorImage) {
    return <p>Error loading image: {data.error.message}</p>;
  }

  return (
    <div
      data-aos="fade-left"
      className="lg:px-12 px-4 flex flex-col md:flex-row justify-center md:items-center items-center gap-4"
    >
      <div className="flex flex-col items-center justify-around mx-auto mb-2 md:mb-0">
        <Title text={t("hero title")} hasAnimate={true} />
        <p
          className="w-[100%] md:w-[75%] mx-auto my-8 text-center"
          style={{ lineHeight: "1.8", fontWeight: "bold" }}
        >
          {/* {t("description")} */}
          {i18n.language === "ar"
            ? dataText?.data?.data?.[0]?.content_ar || "لا يوجد محتوي"
            : dataText?.data?.data?.[0]?.content_en || "there is no content"}
        </p>
        <MainBtn text={t("explore")} action={() => navigate("/courses")} />
      </div>

      <div>
        <img
          data-aos={isArabic ? "fade-right" : "fade-left"}
          src={Studygroup}
          alt="study group img"
          // src={data?.data?.data?.[0]?.image}
          // alt={data?.data?.data?.[0]?.image_name}
          className=" rounded-xl w-full h-auto"
        />
      </div>
    </div>
  );
};

export default Hero;
