import React from "react";
import { Link } from "react-router-dom";
import {
  FaFacebookF,
  FaInstagram,
  FaWhatsapp,
  FaLinkedinIn,
  FaTiktok,
  FaSnapchatGhost,
} from "react-icons/fa";

const socialData = [
  {
    id: 1,
    icon: <FaFacebookF />,
    link: "https://www.facebook.com/profile.php?id=61553760122838&mibextid=ZbWKwL",
  },
  {
    id: 2,
    icon: <FaInstagram />,
    link: "https://www.instagram.com/tsdacadmey/?igsh=MWNxeGZ3dWRlc3hyeA%3D%3D",
  },
  {
    id: 3,
    icon: <FaWhatsapp />,
    link: "https://wa.me/201029126073",
  },
  {
    id: 4,
    icon: <FaLinkedinIn />,
    link: "https://www.linkedin.com/company/tsd.com",
  },
  {
    id: 5,
    icon: <FaTiktok />,
    link: "https://www.tiktok.com/@tsd6874?_t=8qJv2nVRbtg&_r=1",
  },
  {
    id: 6,
    icon: <FaSnapchatGhost />,
    link: "https://www.snapchat.com/add/tsd5667?share_id=B8hl1bxq4po&locale=en-EG",
  },
];

const SocialIcons = () => {
  return (
    <div className="flex items-center gap-3 flex-wrap">
      {socialData.map(({ id, icon, link }) => (
        <Link to={link} key={id} target="_blank" rel="noopener noreferrer">
          <div
            className="flex items-center justify-center w-12 h-12 rounded-full bg-white shadow-lg cursor-pointer hover:scale-105 transition-transform duration-200"
            style={{ color: "#31225b" , fontWeight: "bold" , fontSize: "18px" }}
          >
            {icon}
          </div>
        </Link>
      ))}
    </div>
  );
};

export default SocialIcons;
