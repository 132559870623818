import React, { useEffect, useState } from "react";
import ImageHero from "../components/core/ImageHero";
import { CoursesHero } from "../../assets/DataIndex";
import UserComment from "../components/socialInterAction/UserOpinions/UserComment";
import SendEvaluation from "../components/socialInterAction/UserOpinions/SendEvaluation";
import DataSummary from "../components/socialInterAction/DataSummary ";
import CourseType from "../components/courses/CourseType";

import Title from "../components/core/Title";
import { useTranslation } from "react-i18next";
import CourseCard from "../components/courses/CourseCard";
import Certificate from "../components/courses/Certificate";
import { request } from "../utils/axios";
import { useQuery } from "react-query";
import Spinner from "../components/core/Spinner";
// import FilterCourses from "../components/courses/FilterCourses";

const Courses = () => {
  const { t, i18n } = useTranslation();
  const [courses, setCourses] = useState([]);
  const [selectedCourseType, setSelectedCourseType] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // ================ fetch all courses data =============
  const fetchHeroImage = () => {
    const url = "/themes/courses";
    return request({ url });
  };
  const { data: heroImage } = useQuery("getCoursesHeroImage", fetchHeroImage, {
    onSuccess() {
      // console.log(heroImage?.data?.data);
    },
    onError(e) {
      console.log(e.message);
    },
  });
  const lang = localStorage.getItem("lang")
    ? JSON.parse(localStorage.getItem("lang"))
    : "ar";

  const fetchAllCourses = async () => {
    try {
      setIsLoading(true);
      const url = `/Courses/all?lang=${i18n.language || lang}`;
      const response = await request({ url });
      setCourses(response?.data?.data || []);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAllCourses();
  }, []);

  const handleCourseTypeClick = (categoryId) => {

    setSelectedCourseType(categoryId);
  };

  const filteredCourses = selectedCourseType
    ? courses.filter((course) => {
        return course.id === selectedCourseType;
      })
    : courses;

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="px-3 md:px-16">
      <ImageHero
        img={heroImage?.data?.data[0]?.image}
        title={t("our courses")}
        desc={t("expert desc")}
      />
      <div className="px-20 mb-12">
        <CourseType
          filteredCourses={filteredCourses}
          handleCourseTypeClick={handleCourseTypeClick}
        />
      </div>
      <Title text={t("famous courses")} items="items-center" />

      <div className="lg:px-20 my-12 ">
        {/* <FilterCourses /> */}
        <CourseCard courses={filteredCourses} />
      </div>

      <div className="lg:px-20">
        <Certificate />
      </div>
      <DataSummary />
      <SendEvaluation />
      <div className="flex flex-wrap justify-center items-center gap-x-4 px-8 lg:px-16 mt-12">
        <UserComment />
      </div>
    </div>
  );
};

export default Courses;
