import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaBook } from "react-icons/fa6";
import { IoStar } from "react-icons/io5";

import Pagination from "../core/Pagination";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

const CourseCard = ({ courses }) => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar";
  const navigate = useNavigate();

  const [pageNumber, setPageNumber] = useState(0);
  // pagination
  const usersPerPage = 8;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = courses.length / usersPerPage;
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <motion.div initial={{ opacity: 0, x: 400 }} animate={{ opacity: 1, x: 0 }}>
      <div className="flex justify-center items-center flex-wrap gap-4">
        {courses
          ?.slice(pagesVisited, pagesVisited + usersPerPage)
          ?.map((course) => {
            return (
              <div
                key={course?.id}
                className="w-[260px]   rounded-lg relative"
                style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
                onClick={() => navigate(`/course-details/${course?.id}`)}
              >
                <span
                  className={`bg-[#9E72FB] text-white rounded-2xl py-1 px-2 text-sm absolute top-[2%] ${
                    isArabic ? "right-[5%]" : "left-[5%]"
                  } `}
                  style={{ background: "rgba(158,114,251,.8)" }}
                >
                  {course?.leve}
                </span>

                {/* image card */}
                <img
                  src={course?.image}
                  alt={course?.name}
                  className="w-full rounded-t-lg h-[180px]"
                />

                {/* content card */}
                <div className={`px-4 pb-4 relative `}>
                  <div className="pt-4">
                    <div className="pb-2 max-h-[100px] min-h-[80px]">
                      <span className="text-black opacity-60 font-semibold">
                        {course?.name}
                      </span>

                      <p className="text-black opacity-60 font-bold  ellipsis ">
                        {course?.what_is_expected_}
                      </p>
                    </div>

                    <div className="flex flex-col items-center gap-y-2 mt-2 h-[90px]">
                      <div className="flex justify-between gap-x-16 text-[#31225B] font-semibold py-4">
                        <span className="flex items-center font-semibold gap-x-2  ">
                          <FaBook />
                          {course?.number_of_chapters}
                        </span>
                        <span className="flex items-center gap-x-2 font-semibold">
                          <IoStar className="text-yellow-300" />
                          {course?.rating}
                        </span>
                      </div>
                      <button className="bg-[#31225B] text-white rounded-md  py-2 px-6 text-[14px]">
                        {t("course details")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      <div className=" text-[#31225B] p-2 rounded-xl font-bold   w-44 mx-auto mt-12">
        <Pagination
          activeClassName={"item active "}
          breakClassName={"item break-me "}
          breakLabel={"..."}
          containerClassName={"pagination"}
          disabledClassName={"disabled-page"}
          marginPagesDisplayed={0}
          nextClassName={"item next "}
          nextLabel={">"}
          onPageChange={changePage}
          pageCount={pageCount}
          pageClassName={"item pagination-page "}
          pageRangeDisplayed={2}
          previousClassName={"item previous"}
          previousLabel={"<"}
        />
      </div>
    </motion.div>
  );
};

export default CourseCard;
