import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import FormModel from "../../components/auth/FormModel";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { age_validation } from "../../utils/auth/inputValidations";
import { MdOutlineEmail } from "react-icons/md";

import AuthInput from "../../components/auth/AuthInput";

import { useQuery } from "react-query";
import { request } from "../../utils/axios";
import SelectCity from "../../components/subscription/SelectCity";
import SelectCollege from "../../components/subscription/SelectCollege";
import { subscribeToCourse } from "../../redux/auth/authActions";
import toast from "react-hot-toast";

const CourseSubscripe = ({ handleCloseForm, courseId }) => {
  const { t } = useTranslation();
  const methods = useForm();
  const formRef = useRef();

  useEffect(() => {
    formRef.current && formRef.current.focus();
  }, []);

  const [selectedCityId, setSelectedCityId] = useState(null);
  const [selectedCollegeId, setSelectedCollegeId] = useState(null);
  //  =================== fetchData ===================

  const fetchCities = () => {
    return request({ url: "/city" });
  };
  const {
    isLoading: isLoadingCities,
    data: dataCities,
    error: citiesError,
  } = useQuery("getCityNames", fetchCities);
  if (citiesError) {
    console.error("Error fetching cities:", citiesError);
  }

  const handleCitySelection = (cityId) => {
    setSelectedCityId(cityId);
  };
  //  =================== fetch colleges Data ===================

  const fetchColleges = () => {
    return request({ url: "/college" });
  };
  const { isLoading: isLoadingColleges, data: dataColleges } = useQuery(
    "getCollegeNames",
    fetchColleges
  );
  const handleCollegeSelection = (collegeId) => {
    setSelectedCollegeId(collegeId);
  };

  // =================== subscription methods ===================

  const dispatch = useDispatch();
  const tsdUserToken = localStorage.getItem("tsdUserToken");
  const isUserLoggedIn = !!tsdUserToken;

  const error = useSelector((state) => state.error?.message || null);

  const onSubmit = methods.handleSubmit(async (data) => {
    try {
      const userToken = localStorage.getItem("tsdUserToken");

      if (!isUserLoggedIn) {
        throw new Error("Please log in to subscribe to a course");
      }

      if (!selectedCityId || !selectedCollegeId) {
        throw new Error("Please select a city and a college");
      }

      const requestData = {
        userToken,
        ...data,
        courseId,
        city_id: selectedCityId,
        college_id: selectedCollegeId,
      };

      dispatch(subscribeToCourse(requestData));
      methods.reset();
      toast.success(t("You have successfully subscribed"));
      handleCloseForm();
      setTimeout(() => {
        window.location.reload(false);
      }, 2000);
    } catch (error) {
      console.error("An error occurred:", error.message);
      // You can handle the error here, e.g., display a message to the user
    }
  });

  return (
    <>
      <FormProvider {...methods}>
        <FormModel backFun={handleCloseForm}>
          <div className="text-center flex flex-col items-center justify-between ">
            <h2 className="text-3xl font-semibold mt-6 mb-1">{t("hello")}</h2>
            <span className="text-lg mb-5"></span>
          </div>

          <form onSubmit={onSubmit} noValidate autoComplete="off">
            <div className="flex flex-col justify-center items-center">
              <div>
                <AuthInput {...age_validation} Icon={MdOutlineEmail} />

                <SelectCity
                  citiesData={dataCities?.data?.data}
                  isLoadingCities={isLoadingCities}
                  onSelectCity={handleCitySelection}
                />
                <SelectCollege
                  collegesData={dataColleges?.data?.data}
                  isLoadingColleges={isLoadingColleges}
                  onSelectCollege={handleCollegeSelection}
                />
              </div>
              <button
                className="text-center text-white bg-[#9E72FB] my-4 w-full py-2 rounded-lg"
                type="submit"
              >
                {t("subscribe")}
              </button>
            </div>
          </form>
          {error && (
            <div className="text-red-500 text-center">
              {t("There was an error, try again")}
            </div>
          )}
        </FormModel>
      </FormProvider>
    </>
  );
};

export default CourseSubscripe;
