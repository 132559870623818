import React from "react";
import { Line } from "../../../assets/DataIndex";
import "./title.css";
import { useTranslation } from "react-i18next";

const Title = ({ text, items, hasAnimate }) => {
  const { i18n } = useTranslation();
  const isEnglish = i18n.language === "en";
  const animate = hasAnimate ? "title__animate" : "";
  return (
    <div className={`flex flex-col ${items}`}>
      <p
        className={`${animate} text-[#9E72FB] font-bold pb-3
        ${
          isEnglish
            ? "text-[18px] md:text-[1.5rem] lg:text-[1.8rem]"
            : "text-[1.3rem] md:text-[1.8rem]"
        }
        `}
      >
        {text}
      </p>
      <img src={Line} alt="" className="mb-4" />
    </div>
  );
};

export default Title;
