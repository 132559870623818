import React from "react";

const FormButton = ({ text, submit }) => {
  return (
    <div className="flex justify-center">
      <button
        onClick={submit}
        className="w-[355px] mx-auto my-3 py-3 px-9 rounded-lg outline-none border-none bg-[#31225B] text-white"
      >
        {text}
      </button>
    </div>
  );
};

export default FormButton;
