import React, { useState } from "react";
import teamMember1 from "../../../assets/team/team-member-1.jpg";
import teamMember10 from "../../../assets/team/team-member-10.jpg";
import teamMember11 from "../../../assets/team/team-member-11.png";
import teamMember12 from "../../../assets/team/team-member-12.jpg";
import teamMember2 from "../../../assets/team/team-member-2.jpg";
import teamMember3 from "../../../assets/team/team-member-3.jpg";
import teamMember4 from "../../../assets/team/team-member-4.png";
import teamMember5 from "../../../assets/team/team-member-5.jpg";
import teamMember6 from "../../../assets/team/team-member-6.jpg";
import teamMember7 from "../../../assets/team/team-member-7.jpg";
import teamMember8 from "../../../assets/team/team-member-8.png";
import teamMember9 from "../../../assets/team/team-member-9.png";

const teamMembers = [
  {
    id: 1,
    name: "Dr. Tarek Salah",
    role: "CEO",
    image: teamMember1,
  },
  {
    id: 2,
    name: "Mr. Tarek El-sayegh",
    role: "Training and Development Manager",
    image: teamMember2,
  },
  {
    id: 3,
    name: "Tamer El-rakhawy",
    role: "Art Director",
    image: teamMember3,
  },
  {
    id: 8,
    name: "Kholoud Mohamed",
    role: "Training Coordinator",
    image: teamMember8,
  },
  {
    id: 9,
    name: "Amgd Hatab",
    role: "Marketing Lead",
    image: teamMember9,
  },
  {
    id: 10,
    name: "Aya El-sherbiny",
    role: "Costumer Service",
    image: teamMember10,
  },
  {
    id: 4,
    name: "Mr. Mohamed El-gendy",
    role: "General Manager",
    image: teamMember4,
  },
  {
    id: 12,
    name: "Dr. Waleed Shoaib",
    role: "Special Education Trainer",
    image: teamMember12,
  },
  {
    id: 5,
    name: "Dr. Wafaa El-Tahawy",
    role: "H.R Manager",
    image: teamMember5,
  },
  {
    id: 6,
    name: "Sarah Magdi",
    role: "Costumer Service",
    image: teamMember6,
  },
  {
    id: 7,
    name: "Hagar Ibrahim",
    role: "Training Coordinator",
    image: teamMember7,
  },
  {
    id: 11,
    name: "Salma El-gendy",
    role: "Costumer Service",
    image: teamMember11,
  },
];

const TeamSection = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 py-16">
      <div className="text-center mb-12">
        <h2 className="text-3xl font-bold" style={{ color: "#31225b" }}>
          Our Team
        </h2>
        <p className="text-lg text-gray-600 mt-4">
          Meet our passionate and dedicated team members
        </p>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {teamMembers.map((member) => (
          <div key={member.id} className="bg-white rounded-lg text-center mb-4">
            <img
              src={member.image}
              alt={member.name}
              className="mx-auto  mb-4"
              style={{ borderRadius: "15px"}}
            />
            <h3 className="text-xl font-bold" style={{ color: "#31225b" }}>
              {member.name}
            </h3>
            <p style={{ color: "#9e72fb", fontWeight: "bold" }}>
              {member.role}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TeamSection;
