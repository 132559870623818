import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { request } from "../../utils/axios";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { userLogout } from "../../redux/auth/authActions";
import Spinner from "../core/Spinner";

const UserLogged = () => {
  const { t } = useTranslation();
  // const isArabic = i18n.language === "ar";
  const [open, setOpen] = useState(false);
  // ==================Fetch data =========
  const fetchData = () => {
    return request({ url: "/myProfile" });
  };

  const { isLoading, data } = useQuery("getUserData", fetchData, {
    onSuccess: () => {
      // console.log(data?.data?.data);
    },
  });
  // if (isLoading) {
  //   return <Spinner />;
  // }
  const userName = data?.data?.data?.Name;

  const formattedUserName = userName
    ?.split(" ")
    ?.map((word) => (word ? word[0] : ""))
    ?.join("");

  // ================ logout function ================
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(userLogout());
    window.location.reload(false);
  };
  // console.log(formattedUserName);
  return (
    <div
      className=" rounded-lg py-1 px-1 md:px-2 cursor-pointer relative"
      onClick={() => setOpen(!open)}
    >
      {data?.data?.data?.profile_picture ===
      "https://api.tsd-education.com/storage" ? (
        <div className="relative inline-flex items-center justify-center w-12 h-12 overflow-hidden rounded-lg  bg-gray-600">
          <span className="font-medium  text-white">{formattedUserName}</span>
        </div>
      ) : (
        <img
          src={data?.data?.data?.profile_picture}
          alt="user"
          className="w-12 h-12 rounded-lg object-cover"
        />
      )}

      {open && (
        <div
          className={`absolute bg-white rounded-lg top-[100%] w-[160px] flex flex-col gap-y-1  py-3 px-4 shadow-xl z-[50]`}
        >
          <div className="flex flex-col justify-center items-center">
            {data?.data?.data?.profile_picture ===
            "https://api.tsd-education.com/storage" ? (
              <div className="relative inline-flex items-center justify-center w-12 h-12 overflow-hidden rounded-lg  bg-gray-600">
                <span className="font-medium  text-white">
                  {formattedUserName}
                </span>
              </div>
            ) : (
              <img
                src={data?.data?.data?.profile_picture}
                alt="user"
                className="w-12 h-12 rounded-lg object-cover"
              />
            )}

            <span className="font-bold">{data?.data?.data?.Name}</span>
          </div>
          <Link
            to="/my-profile"
            className="text-[14px] pb-1 border-b-2 font-semibold"
          >
            {t("my account")}
          </Link>
          <span
            to="/my-profile"
            className="text-[14px] font-semibold"
            onClick={handleLogout}
          >
            {t("logout")}
          </span>
        </div>
      )}
    </div>
  );
};

export default UserLogged;
