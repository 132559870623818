import React, { useEffect } from "react";
// import ReactPlayer from "react-player";

import SocialIcons from "../components/core/SocialIcons";
import DataSummary from "../components/socialInterAction/DataSummary ";
import SendEvaluation from "../components/socialInterAction/UserOpinions/SendEvaluation";
import UserComment from "../components/socialInterAction/UserOpinions/UserComment";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { request } from "../utils/axios";
import { useQuery } from "react-query";
import Spinner from "../components/core/Spinner";

const StdBusinessDetails = () => {
  const { t, i18n } = useTranslation();

  const { id } = useParams();

  const lang = localStorage.getItem("lang")
    ? JSON.parse(localStorage.getItem("lang"))
    : "ar";

  const fetchData = () => {
    const url = `/Works/About/${id}?lang=${i18n.language || lang}`;
    return request({ url });
  };

  const { isLoading, data, refetch } = useQuery(
    "getStdCourseDetails",
    fetchData,
    {
      onSuccess() {
        console.log(data?.data?.data);
      },
    }
  );
  useEffect(() => {
    refetch();
  }, [i18n.language]);

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <div>
      <div className="  px-4 md:w-[60%] mx-auto">
        {/* <ReactPlayer
          url={Anime} // Replace with your video URL
          width="100%"
          height="100%"
          controls={true}
          playing={false} // Set to true if you want the video to start playing automatically
          loop={false}
          volume={0.8} // Volume level (0 to 1)
          muted={false}
          playbackRate={1} // Playback speed (1 is normal speed)
          style={{ borderRadius: "20px" }} // Add custom styles
        /> */}
        <iframe
          src={data?.data?.data?.works_demo}
          title="description"
          className="w-full h-[400px] rounded-xl"
        ></iframe>
        {/* <img src="" alt="" className=""/> */}
      </div>
      {/* <div className=" flex justify-center items-center gap-x-4 flex-wrap my-12 ">
        <CourseInfo />
      </div> */}
      <div className="px-8 md:px-32 mt-8">
        {/* {data.map((item, index) => (
          <CourseDesc key={index} data={item} />
        ))} */}
        <div className="flex items-center gap-x-3 px-5">
          <span className="font-semibold">{t("under supervision")} /</span>
          <p className="text-[#9E72FB] font-extrabold">
            {data?.data?.data?.trainer?.name}
          </p>
        </div>
        <div className="flex items-center gap-x-3 px-5 mt-2">
          <span className="font-semibold"> {t("student name")} /</span>
          <p className="text-[#9E72FB] font-extrabold">
            {" "}
            {data?.data?.data?.name}
          </p>
        </div>
        <div className="flex  gap-x-3 px-5 mt-4">
          <span className="font-bold">{t("descCourse")}: </span>
          <p className="text-[#9E72FB] font-semibold md:w-[85%]">
            {" "}
            {data?.data?.data?.description}
          </p>
        </div>{" "}
        <div className="flex  gap-x-3 px-5 mt-4">
          <span className="font-bold">{t("course name")} : </span>
          <p className="text-[#9E72FB] font-semibold">
            {" "}
            {data?.data?.data?.course?.name}
          </p>
        </div>
      </div>

      <div className="flex justify-center items-center gap-x-2 my-8">
        {t("you can share course via")}
        <SocialIcons />
      </div>
      <DataSummary />
      <SendEvaluation />
      <div className="flex flex-wrap justify-center items-center gap-x-4  px-8 lg:px-16 mt-12">
        <UserComment />
      </div>
    </div>
  );
};

export default StdBusinessDetails;
