import React from "react";
import Navbar from "../navbar/Navbar";

import { Outlet, useLocation } from "react-router-dom";
import Footer from "../core/Footer";

const Root = () => {
  const location = useLocation();

  return (
    <div>
      <Navbar />
      <div
        className={`mt-24 md:mt-12 ${
          location === "/my-profile" && "bg-[#F7F8FB]"
        }`}
      >
        <Outlet />
      </div>
      <div className="mt-[120px]">
        <Footer />
      </div>
    </div>
  );
};

export default Root;
