import {
  faCertificate,
  faChalkboardTeacher,
  faShieldAlt,
  faUserGraduate,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { request } from "../../utils/axios";

const DataSummary = () => {
  const { t } = useTranslation();
  const fetchStatistics = () => {
    const url = `/statistics`;
    return request({ url });
  };

  const { isLoading, data, refetch } = useQuery(
    "getAllCourses",
    fetchStatistics,

    {
      onSuccess() {
        // console.log(data?.data?.data?.data?.user_total);
      },
      onError(error) {
        console.error(error);
      },
    }
  );
  return (
    <div className="mt-24 flex justify-center items-center flex-wrap gap-4">
      {/* Box 1 - Accreditation Guarantee */}
      <div className="flex flex-col items-center border border-gray-300 py-6 px-24 rounded-lg shadow-lg bg-[#31225b]">
        <FontAwesomeIcon
          icon={faShieldAlt}
          className="text-4xl text-white mb-4"
        />
        <span className="font-extrabold text-3xl text-white">100%</span>
        <p className="text-sm md:text-base md:font-semibold text-white mt-1">
          {t("Accreditation guarantee")}
        </p>
      </div>

      {/* Box 2 - Student Number */}
      <div className="flex flex-col items-center border border-gray-300 bg-[#31225b] py-6 px-24 rounded-lg shadow-lg">
        <FontAwesomeIcon
          icon={faUserGraduate}
          className="text-4xl text-white mb-4"
        />
        <span className="font-extrabold text-3xl text-white">
          {data?.data?.data?.data?.user_total}
        </span>
        <p className="text-sm md:text-base md:font-semibold text-white mt-1">
          {t("Student Number")}
        </p>
      </div>

      {/* Box 3 - Number of Certificates */}
      <div className="flex flex-col items-center border border-gray-300 bg-[#31225b] py-6 px-12 rounded-lg shadow-lg">
        <FontAwesomeIcon
          icon={faCertificate}
          className="text-4xl text-white mb-4"
        />
        <span className="font-extrabold text-3xl text-white">
          {data?.data?.data?.data?.Certificate}
        </span>
        <p className="text-sm md:text-base md:font-semibold text-white mt-1">
          {t("num of certificates")}
        </p>
      </div>

      {/* Box 4 - Experience */}
      <div className="flex flex-col items-center border border-gray-300 bg-[#31225b] py-6 px-24 rounded-lg shadow-lg">
        <FontAwesomeIcon
          icon={faChalkboardTeacher}
          className="text-4xl text-white mb-4"
        />
        <span className="font-extrabold text-3xl text-white">
          {data?.data?.data?.data?.trainer_total}
        </span>
        <p className="text-sm md:text-base md:font-semibold text-white mt-1">
          {t("experience")}
        </p>
      </div>
    </div>
  );
};

export default DataSummary;
