import React from "react";

const MainBtn = ({ text ,action }) => {
  return (
    <button onClick={action} className="bg-[#9E72FB] text-white py-2 px-4 min-w-[150px] my-1 rounded-2xl text-sm xl:text-base">
      {text}
    </button>
  );
};

export default MainBtn;
