import React, { useEffect } from "react";
import { FeatureImage } from "../../../assets/DataIndex";
import Title from "../core/Title";
import { useTranslation } from "react-i18next";
import { request } from "../../utils/axios";
import { useQuery } from "react-query";
import AOS from "aos";
import "aos/dist/aos.css";
const Features = () => {
  const { t, i18n } = useTranslation();
  const fetchData = () => {
    const url = "/themes/home";
    return request({ url });
  };
  const { isLoading, data, refetch, isError } = useQuery(
    "getHeroImage",
    fetchData
  );
  const fetchText = () => {
    const url = "/ContentText/home/*";
    return request({ url });
  };
  useEffect(() => {
    AOS.init();
  }, []);
  const {
    data: dataText,
    error,
    isLoading: text,
  } = useQuery("getHomeText", fetchText, {
    onSuccess() {
    },
  });

  if (error) {
    return <p>Error: {error.message}</p>;
  }
  return (
    <div className="px-0 lg:px-12 flex-col md:flex-row flex gap-4  justify-center items-center">
      <div className="w-full mx-auto  md:mb-0 md:w-[50%] ">
        <Title text={t("provided with the platform")} items="items-center" />
        <p className=" mt-4 text-center text-sm md:text-base">
          {i18n.language === "ar"
            ? dataText?.data?.data?.[1]?.content_ar
            : dataText?.data?.data?.[1]?.content_en}
        </p>
      </div>

      <img
        data-aos="flip-left"
        src={data?.data?.data?.[1]?.image}
        alt={data?.data?.data?.[1]?.image_name}
        className="max-w-[75%] rounded-lg"
      />
    </div>
  );
};

export default Features;
