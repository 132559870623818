import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { IoMdPerson } from "react-icons/io";

import { Link, useLocation } from "react-router-dom";
import Language from "../language/Language";

import { usePopupContext } from "../../context/PopupProvider";
import Login from "../../view/auth/Login";
import { Sidebar } from "./Sidebar";

import { useQuery } from "react-query";
import { Logo } from "../../../assets/DataIndex";
import { request } from "../../utils/axios";
import UserLogged from "./UserLogged";

const navLinks = [
  { name: "home", route: "/" },
  { name: "courses", route: "/courses" },
  { name: "student business", route: "/student-business" },
  { name: "experience", route: "/our-expert" },
  { name: "us", route: "/about-us" },
  { name: "contact", route: "/contact-us" },
];

const NavLinks = () => {
  const { pathname } = useLocation();
  const [activeNav, setActiveNav] = useState(pathname);
  const [showNav, setShowNav] = useState(false);
  const { t } = useTranslation();
  const { openLogin, showLogin } = usePopupContext();

  const handleActiveLink = (index) => {
    setActiveNav(index);
    setShowNav(false);
  };
  // const handleShowNav = () => {
  //   setShowNav(!showNav);
  // };

  // ==================Fetch data =========
  const fetchData = () => {
    return request({ url: "/myProfile" });
  };

  const { isLoading, data } = useQuery("getUserData", fetchData, {
    onSuccess: () => {
      if (!isLoading) {
      } else {
        return;
      }
    },
    onError: () => console.log("error", data),
  });
  return (
    <>
      <div className="w-full px-6 mt-3 hidden md:flex items-center justify-between">
        <Logo />
        <ul className="flex items-center justify-center">
          {navLinks.map((link, index) => (
            <li
              key={index}
              onClick={() => handleActiveLink(link.route)}
              className="mx-2 lg:mx-4 cursor-pointer"
            >
              <Link
                to={link.route}
                className={`${
                  activeNav === link.route ? "text-[#9e72fb]" : null
                } font-medium text-sm lg:text-base `}
              >
                {t(link.name)}
              </Link>
            </li>
          ))}
        </ul>
        <div className="flex items-center gap-x-2">
          {data?.data?.data?.id ? (
            <UserLogged />
          ) : (
            <button className=" w-12 h-12  bg-[#9e72fb] text-white rounded-xl py-1 px-3">
              <IoMdPerson size={25} onClick={openLogin} />
            </button>
          )}
          {showLogin && <Login />}
          <Language />
          
        </div>
      </div>
      {/* responsive nav links */}
      <div className="md:hidden w-full p-2  flex items-center justify-between fixed top-0 bg-white shadow-lg z-[1000] mb-12">
        <div className="flex gap-x-2">
          {data?.data?.data?.id ? (
            <UserLogged />
          ) : (
            <>
              <button className=" w-12 h-12 bg-[#9e72fb] text-white rounded-xl py-1 px-3">
                <IoMdPerson size={25} onClick={openLogin} />
              </button>
              {showLogin && <Login />}
            </>
          )}

          <Language />
        </div>
        <Sidebar />
      </div>
    </>
  );
};

export default NavLinks;
